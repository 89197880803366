const items = [
    {
        img: 'IMG_0893 (1).jpg'
    },
    {
        img: 'IMG_0916.jpg'
    },
    {
        img: 'IMG_0922 (1).jpg'
    },
    {
        img: 'IMG_0950 (2).jpg'
    },
    {
        img: 'IMG_1195.jpg'
    },
    {
        img: 'IMG_1628.jpg'
    },
    {
        img: 'IMG_2787.jpg'
    },
    {
        img: 'IMG_2815.jpg'
    },
    {
        img: 'IMG_2821.jpg'
    },
    {
        img: 'IMG_2823.jpg'
    },
    {
        img: 'IMG_2940.jpg'
    },
    {
        img: 'IMG_3701.jpg'
    },
    {
        img: 'IMG_3707 (1).jpg'
    },
    {
        img: 'IMG_3838 (1).jpg'
    },
    {
        img: 'IMG_3840.jpg'
    },
    {
        img: 'IMG_3850.jpg'
    },
    {
        img: 'IMG_5218.jpg'
    },
    {
        img: 'IMG_5224.jpg'
    },
    {
        img: 'IMG_5226 (1).jpg'
    },
    {
        img: 'IMG_5711.jpg'
    },
    {
        img: 'IMG_7161.jpg'
    },
    {
        img: 'IMG_7165.jpg'
    },
    {
        img: 'IMG_7167.jpg'
    },
    {
        img: 'IMG_7168.jpg'
    },
    {
        img: 'IMG_8687 (1).jpg'
    },
    {
        img: 'IMG_8688.jpg'
    }
]

export default items;